@font-face {
    font-family: 'gillSans';
    font-style: normal;
    font-weight: normal;
    src: url('/storage/fonts/GillSans.woff') format("woff");
}

html,
body {
    scroll-behavior: smooth;
    overflow: visible !important;
    background-color: #F9FAFD;
}

body::-webkit-scrollbar {
    width: 5px;
    height: 7px;
}
body::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
body::-webkit-scrollbar-thumb {
    background: #525965;
    border: 0px none #ffffff;
    border-radius: 0px;
}
body::-webkit-scrollbar-thumb:hover {
    background: #525965;
}
body::-webkit-scrollbar-thumb:active {
    background: #525965;
}
body::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
}
body::-webkit-scrollbar-track:hover {
    background: transparent;
}
body::-webkit-scrollbar-track:active {
    background: transparent;
}
body::-webkit-scrollbar-corner {
    background: transparent;
}

.text-platinum {
    color: $platinum !important;
}

.text-gold {
    color: $gold !important;
}

.text-silver {
    color: $silver !important;
}

.text-teal {
    color: $teal !important;
}

.text-dark-green {
    color: $darkGreen !important;
}

a.text-teal:hover,
a.text-teal:focus {
    color: #2c7a7b !important;
}

.text-orange {
    color: $orange !important;
}

a.text-orange:hover,
a.text-orange:focus {
    color: #db751b !important;
}

.btn-teal {
    color: #fff;
    background-color: $teal;
    border-color: $teal;
    box-shadow: 0 4px 10px rgb(31 186 162 / 50%);
    padding: 12px 30px;
    border-radius: 12px;
    transition: .5s ease;
}

.btn-teal:hover {
    color: #fff;
    box-shadow: 0 6px 20px rgb(133 224 210 / 50%);
}

// .btn-teal {
//     color: #fff;
//     background-color: $teal;
//     border-color: $teal;
//     padding: 0.4rem 0.75rem;
// }

// .btn-teal:hover {
//     color: #fff;
//     opacity: 0.8;
// }

.btn-teal:focus,
.btn-teal.focus {
    color: #fff;
    opacity: 0.8;
    box-shadow: 0 0 0 0.2rem rgb(69, 222, 214);
}

.btn-teal.disabled,
.btn-teal:disabled {
    color: #fff;
    background-color: $teal;
    border-color: $teal;
}

.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active,
.show>.btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #2c7a7b;
    border-color: #2c7a7b;
}

.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus,
.show>.btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(69, 222, 214);
}

.btn-outline-teal {
    background: $white;
    border-color: $teal;
    color: $teal;
    transition: .5s ease;
}

.btn-outline-teal:hover {
    color: $teal;
    box-shadow: 0 4px 10px $teal;
}

.btn-outline-teal:focus,
.btn-outline-teal.focus {
    box-shadow: 0 0 0 0.2rem rgb(69, 222, 214);
}

.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
    color: $teal;
    background-color: transparent;
}

.btn-outline-teal:not(:disabled):not(.disabled):active,
.btn-outline-teal:not(:disabled):not(.disabled).active,
.show>.btn-outline-teal.dropdown-toggle {
    color: #fff;
    background-color: $teal;
    border-color: $teal;
}

.btn-outline-teal:not(:disabled):not(.disabled):active:focus,
.btn-outline-teal:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(69, 222, 214);
}

.btn-orange {
    color: #fff;
    background-color: $orange;
    border-color: $orange;
    box-shadow: 0 4px 10px rgb(241 132 37 / 50%);
    padding: 13px 30px;
    border-radius: 12px;
}

.btn-orange:hover {
    color: #fff;
    box-shadow: 0 6px 20px rgb(255 184 122 / 50%);
}

.btn-orange:focus,
.btn-orange.focus {
    color: #fff;
    background-color: #db751b;
    border-color: #db751b;
    box-shadow: 0 0 0 0.2rem rgb(222, 138, 69);
}

.btn-orange.disabled,
.btn-orange:disabled {
    color: #fff;
    background-color: $orange;
    border-color: $orange;
}

.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show>.btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #db751b;
    border-color: #db751b;
}

.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show>.btn-orange.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(222, 138, 69);
}

.btn-outline-orange {
    color: $orange;
    border-color: $orange;
}

.btn-outline-orange:hover {
    color: #fff;
    background-color: $orange;
    border-color: $orange;
}

.btn-outline-orange:focus,
.btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgb(222, 138, 69);
}

.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
    color: $orange;
    background-color: transparent;
}

.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled).active,
.show>.btn-outline-orange.dropdown-toggle {
    color: #fff;
    background-color: $orange;
    border-color: $orange;
}

.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-orange.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(222, 138, 69);
}

.bg-teal {
    background-color: $teal !important;
}

a.bg-teal:hover,
a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
    background-color: #2c7a7b !important;
}

.bg-orange {
    background-color: $orange !important;
}

a.bg-orange:hover,
a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
    background-color: #db751b !important;
}

.bg-gray {
    background-color: #6c757d !important;
}

a.bg-gray:hover,
a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
    background-color: #626a70 !important;
}

.border-teal {
    border-color: $teal !important;
    border-width: 0.1875rem !important;
}

.border-orange {
    border-color: $orange !important;
    border-width: 0.1875rem !important;
}

.border-orange-lg {
    border-color: $orange !important;
    border-width: 0.375rem !important;
}

.shadow-xsm {
    box-shadow: 0 2px 16px rgb(32 108 164 / 16%) !important;
}

.target {
    position: relative;
    text-align: center;
}

.target>a {
    position: absolute;
    left: 0;
    top: -57px;
}

.navbar-nav .nav-link {
    color: #6D6D6D !important;
    padding: 0.5rem 0.75rem !important;
    transition: 0.1s ease-in-out;
    transition: .5s ease;
}

.navbar-nav .nav-link:hover,
.nav-link.active,
.nav-link:active {
    color: $darkGreen !important;
}

.dropdown-menu {
    margin: 0.52em 0;
    transition: .5s ease;
}

.dropdown-item:hover {
    color: $darkGreen;
    background-color: transparent !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: $darkGreen;
    background-color: transparent !important;
}

.section-header h2 {
    font-style: normal;
    font-variant: normal;
    position: relative;
    padding-bottom: 10px;
    color: #1db9a1;
    text-align: center;
}

.section-header h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #dddddd;
    bottom: 1px;
    left: calc(50% - 60px);
}

.section-header h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #f18425;
    bottom: 0;
    left: calc(50% - 20px);
    padding-bottom: -50px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.7;
    z-index: 0;
}

// @include media-breakpoint-down(sm) {
//     .masthead {
//         background-position: -15rem;
//     }
// }
.title {
    font-size: 2rem;
    margin-bottom: 0.15rem;
    font-family: 'gillSans';
}

.subtitle {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 0.5rem;
}

.content {
    font-weight: 400;
}

@include media-breakpoint-up(md) {
    .title {
        font-size: 2.5rem;
    }

    .subtitle {
        font-size: 1.5rem;
    }
}

@include media-breakpoint-up(lg) {
    .title {
        font-size: 3.5rem;
        margin-bottom: 0;
        line-height: 1.2;
    }

    .subtitle {
        font-size: 2rem;
        line-height: 1.25;
    }
}

// .slider-carousel .item {
//     opacity: 0.6;
//     transition: 0.4s ease all;
//     transform: scale(0.8);
// }
// @include media-breakpoint-down(lg) {
//     .slider-carousel .item {
//         margin: 0;
//         transform: scale(0.9);
//     }
// }


// .slider-carousel .active .item {
//     opacity: 1;
//     transform: scale(1);
// }
// .slider-carousel .active {
//     z-index: 99;
// }
// .slider-carousel .owl-item {
//     backface-visibility: hidden;
//     -webkit-backface-visibility: hidden;
//     transform: translateZ(0) scale(1, 1);
//     -webkit-transform: translateZ(0) scale(1, 1);
// }

@include media-breakpoint-up(xl) {
    .platinum-partnerships-carousel .owl-stage {
        left: -110px;
    }

    .gold-partnerships-carousel .owl-stage {
        left: -110px;
    }

    .silver-partnerships-carousel .owl-stage {
        left: -110px;
    }
}

// @include media-breakpoint-up(md) {
//     .news-carousel .owl-stage {
//         left: -130px;
//     }
// }

// @include media-breakpoint-up(lg) {
//     .news-carousel .owl-stage {
//         left: -100px;
//     }
// }
.bg-hover:hover {
    background-color: $orange !important;
    border-color: $orange !important;
    transition: ease-out .2s;
    color: white;
}

.card-bordered {
    transition: .5s ease;
    border: 2px solid #ddd;
}

.card-bordered:hover {
    border: 2px solid $darkGreen;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 12px, rgba(0, 0, 0, 0.05) 0px 0px 8px;
    transform: translateY(-8px);
}

.card-shadow-sm {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.powered-by img {
    filter: grayscale(100%);
    opacity: 0.6;
    transition: .5s ease;
}

.powered-by img:hover {
    filter: grayscale(0);
    opacity: 1;
}

.w-150p {
    width: 9.375rem !important;
}

.w-120p {
    width: 7.5rem !important;
}

.w-100p {
    width: 6.25rem !important;
}

.w-70p {
    width: 70px !important;
}

.w-50p {
    width: 50px !important;
}

.h-100p {
    height: 6.25rem !important;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link:active {
    color: $white !important;
    background-color: $teal;
    font-weight: bold !important;
}


@include media-breakpoint-up(md) {
    .flex-md-column {
        flex-direction: column !important;
    }

    .rounded-md-top {
        border-top-left-radius: 0.25rem !important;
        border-top-right-radius: 0.25rem !important;
        border-bottom-left-radius: 0 !important;
    }

    .rounded-md-bottom {
        border-bottom-left-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
        border-top-right-radius: 0 !important;
    }
}

// @include media-breakpoint-down(sm) {
//     .overflow-md-auto {
//         overflow: auto !important;
//     }
//     .h-350p {
//         height: 350px;
//     }
// }


.btn-radio {
    color: #fff;
    background-color: #666666;
    border-color: #666666;
}

.btn-radio:hover {
    color: #fff;
    background-color: #fab273;
    border-color: #fab273;
}

.btn-radio.focus,
.btn-radio:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #f18425;
    box-shadow: 0 0 0 0.2rem #f18425;
}

.btn-radio:not(:disabled):not(.disabled).active,
.btn-radio:not(:disabled):not(.disabled):active,
.show>.btn-radio.dropdown-toggle {
    color: #fff;
    background-color: #f18425;
    border-color: #f18425;
}

.btn-radio:not(:disabled):not(.disabled).active:focus,
.btn-radio:not(:disabled):not(.disabled):active:focus,
.show>.btn-radio.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #f18425;
    box-shadow: 0 0 0 0.2rem #f18425;
}

.btn-radio-green {
    color: $darkGreen;
    background-color: transparent;
    border-color: $darkGreen;
}

.btn-radio-green:hover {
    color: #fff;
    background-color: $darkGreen;
    border-color: $darkGreen;
}

.btn-radio-green.focus,
.btn-radio-green:focus {
    -webkit-box-shadow: 0 0 0 0.2rem $darkGreen;
    box-shadow: 0 0 0 0.2rem $darkGreen;
}

.btn-radio-green:not(:disabled):not(.disabled).active,
.btn-radio-green:not(:disabled):not(.disabled):active,
.show>.btn-radio-green.dropdown-toggle {
    color: #fff;
    background-color: $darkGreen;
    border-color: $darkGreen;
}

.btn-radio-green:not(:disabled):not(.disabled).active:focus,
.btn-radio-green:not(:disabled):not(.disabled):active:focus,
.show>.btn-radio-green.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem $darkGreen;
    box-shadow: 0 0 0 0.2rem $darkGreen;
}


.card-columns.card-dashboard {
    @include media-breakpoint-up(sm) {
        column-count: 1;
    }

    @include media-breakpoint-up(xl) {
        column-count: 2;
    }
}

.card-columns.card-articles {
    @include media-breakpoint-up(sm) {
        column-count: 2;
    }

    @include media-breakpoint-up(md) {
        column-count: 3;
    }
}

.page-item.active .page-link {
    color: #fff;
    background-color: $teal;
    border-color: $teal;
}

.page-link {
    color: $teal;
}

.page-link:hover {
    color: $teal;
}

.page-link:focus {
    box-shadow: none;
}

// General
// body{
//      animation: transitionIn 0.2s ease-in;
//  }

//  @keyframes transitionIn{
//      from{
//          opacity: 0;
//      }
//      to{
//          opacity: 1;
//      }
//  }

//  body.hide{
//      animation: transitionOut 0.2s ease-out;
//  }

//  @keyframes transitionOut{
//      from{
//          opacity: 1;
//      }
//      to{
//          opacity: 0;
//      }
//  }

.bg-teal {
    background-color: #1db9a1;
}

.bg-orange {
    background-color: #f18425;
}

.text-teal {
    color: #1db9a1;
}

.text-orange {
    color: #f18425;
}

.border-teal {
    border: 2px solid #1db9a1;
}

.border-orange {
    border: 2px solid #f18425;
}

.text-landing {
    font-size: 62px;
    font-weight: 700;
    line-height: 1.2;
    font-family: 'gillSans';
    letter-spacing: .05em;
}

.media-title {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 600;
    transition: .5s ease;
}

.media-title:hover {
    color: $teal;
}

.text-arrow{
    color: $teal !important;
}

.text-arrow i {
    transition: .5s ease;
}

.text-arrow:hover {
    .fa-arrow-right {
        margin-left: 8px;
    }
}

.text-small {
    font-size: 14px !important;
    color: #A1A2AB;
}

.media-hover .text-arrow:hover {
    color: $teal;
}

.text-sub-landing {
    font-size: 46px;
    line-height: 1.4;
    font-weight: 700;
    font-family: 'gillSans';
}

.text-landing-desc {
    font-size: 20px;
    color: $silver;
    line-height: 1.4;
    font-weight: 500 !important;
}

.subpage-landing {
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: ">";
}

.breadcrumb >.active {
   color: $darkGreen !important;
}

.py-2rem {
    padding: 2rem 0;
}

.py-3rem {
    padding: 3rem 0;
}

.py-4rem {
    padding: 4rem 0;
}

.py-5rem {
    padding: 5rem 0;
}

.pt-5rem {
    padding-top: 5rem;
}

.pb-5rem {
    padding-bottom: 5rem;
}

// Navbar
.navbar {
    background-color: white;
    box-shadow: 0px 1px 7px gray;
}

.nav-button {
    background-color: transparent;
    color: #6D6D6D;
    padding: 0.3rem 1rem;
    border-radius: 50px;
}

.nav-button:hover {
    background-color: #1db9a1;
    color: white;
}

// Footer
.footer {
	position: relative;
	color: $white;
	padding: 4rem 0;
	background-color: #141d2a;
	.container {
		position: relative;
	}
	h3 {
		font-size: 16px;
		margin-bottom: 10px;
		margin-top: 0;
		line-height: 1.5;
	}

	.links {
        -webkit-columns: 2;
        columns: 2;
		li {
			margin-bottom: 10px;
			line-height: 1.5;
			display: block;
			a {
                transition: .5s ease;
				color: #666873;
				&:hover {
					color: $teal;
				}
			}
		}
	}

	.social {
		li {
			display: inline-block;
			position: relative;
			a {
				position: relative;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				display: inline-block;
                margin-right: .25rem;
				padding: 0;
				background-color: $teal;
				color: #fff;
				> span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
	.copyright {
		color: #666873;
	}

	.cta {
		box-shadow: -20px -20px 0 0 rgba($dark, .2);
		padding: 20px;
		background-color: $teal;
		top: -100px;
		position: relative;
		h2, h3 {
			line-height: 1.5;
		}
		h3 {
			font-size: 20px;
		}
	}
}

// Board of Management
.bom-row {
    padding-bottom: 3rem;
}

.bom-nav a {
    border-radius: 12px !important;
}

.bom-card {
    padding: 0.5rem 1rem;
    border-radius: 60px;
    background-color: white;
    border: 1px solid #f18425;
    z-index: 1;
    position: relative;
}

.text-division {
    font-size: 36px;
}

.bom-button {
    cursor: pointer;
    transition: 300ms;
    padding: 0 !important;
}

.bom-button:hover {
    transform: scale(1.05);
}

// About
.bg-about {
    background: url('/storage/images/assets/about-bnec.png') top center no-repeat;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: 25% 75%;
}

.bg-quote {
    background: url('/storage/images/assets/quote.webp') top left no-repeat, $white;
    background-size: 70px;
    background-position: 32% 7%;
}

.bg-rounded-rectangle {
    background: url('/storage/images/assets/rounded-rectangle.webp') no-repeat
        , url('/storage/images/assets/rounded-rectangle.webp') no-repeat, $teal;
    background-size: 350px, 350px, cover;
    background-position: -15% 20%, 115% 80%;
}

.rounded-xl {
    border-radius: 12px;
}

.rounded-xxl {
    border-radius: 20px;
}

.rounded-100-dleft {
    border-radius: 0 100px 0 100px;
}

.rounded-100-dright {
    border-radius: 100px 0 100px 0;
}

.section-title {
    font-family: 'gillSans';
    font-weight: 900;
    z-index: 100;
}

// Home - Landing Page
.section-bg-1 {
    background: url('/storage/images/assets/light-green-circle.webp') no-repeat
        , url('/storage/images/assets/dotted-square.webp') no-repeat, $white;
    background-size: 200px, 175px, cover;
    background-position: 12% 20%, 40% 80%, 50%;
    min-height: 110vh;
    height: 110vh;
}

.section-bg-mix-pattern {
    background: url('/storage/images/assets/light-green-circle.webp') no-repeat,
    url('/storage/images/assets/light-green-circle.webp') no-repeat,
    url('/storage/images/assets/orange-dotted-square.webp') no-repeat, $white;
    background-size: 300px, 100px, 200px;
    background-position: 5% -5%, 90% 10%, 95% 60%;
}

.section-bg-light-green {
    background-color: #E8FEF8;
}

.section-bg-media-news {
    padding: 3rem 0;
    background: url('/storage/images/assets/light-green-circle.webp') no-repeat, 
                url('/storage/images/assets/dotted-square.webp') no-repeat, 
                url('/storage/images/assets/dotted-square.webp') no-repeat, $white;
    background-size: 185px, 150px, 120px, cover;
    background-position: -6% 30%, 45% -10%, 60% 85%, 50%;
}

.section-bg-media-article {
    padding: 3rem 0;
    background: url('/storage/images/assets/light-green-circle.webp') no-repeat, 
                url('/storage/images/assets/dotted-square.webp') no-repeat, $white;
    background-size: 250px, 150px, cover;
    background-position: 90% 10%, 35% 75%, 50%;
}

.section-bg-gradient {
    padding: 3rem 0;
    background: #D2FFFB;
    background: -webkit-linear-gradient(left, #D2FFFB, #20BAA3);
    background: -moz-linear-gradient(left, #D2FFFB, #20BAA3);
    background: linear-gradient(to right, #D2FFFB, #20BAA3);
}

.bg-white {
    background-color: $white;
}

.text-orange-gradient {
    background-image: linear-gradient($orange, #FFB87A) !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-title-sm {
    font-family: 'gillSans';
    text-transform: uppercase;
    color: $darkGreen;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 4px;
}

// Home
.masthead {
    background: url('/storage/images/home/landing_background.webp');
    min-height: 110vh;
    height: 110vh;
    background-size: cover;
    background-position: bottom center;
    border-radius: 0 0 100px 0;
}

.btn-hover {
    padding: 0.4rem 1rem;
    color: white;
    background: #f18425;
}

.btn-hover:hover {
    background: #e27718;
    color: white;
}

// Partnership
.partnership-hover-card {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(26, 33, 37, .72);
    color: white;
    visibility: visible;
    opacity: 0;
    transition: 200ms ease;
    margin: 0 0.9rem;
}

.partnership-poster:hover>.partnership-hover-card {
    opacity: 1;
    visibility: visible;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.card-person {
    transition: 0.3s ease;
    background: $orange;
    color: white;
}

.card-person:hover {
    background: #e06900;
    transform: scale(1.04);
}

.text-dark-green {
    color: $darkGreen;
}

.bg-dark-green {
    background-color: $darkGreen;
}

.callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
  border-radius: 6px;
  background-color: #fff;
}

.callout h4 {
  margin-top: 0;
}

.callout p:last-child {
  margin-bottom: 0;
}

.callout code,
.callout .highlight {
  background-color: #fff;
}

.callout-info {
    border-color: $blue;
}

.callout-primary {
    border-color: $teal;
}

.callout-warning {
    border-color: $yellow;
}

.callout-danger {
    border-color: $red;
}

.callout-dark-green {
    border-color: $darkGreen;
}

.btn-light:hover {
    background-color: transparent !important;
    color: $white !important;
}