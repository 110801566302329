#scroll-up {
    display: none;
    position: fixed;
    bottom: 0.75rem;
    right: 1rem;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #f18425;
    color: white;
    cursor: pointer;
    padding: 0.75rem 1rem;
    border-radius: 30px;
}
#scroll-up:hover {
    background-color: #555;
}
