// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fontawesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

// Owl Carousel
@import '~owl.carousel/dist/assets/owl.carousel';
@import '~owl.carousel/dist/assets/owl.theme.default';

// Datatables Bootstrap 4
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';

@import './component.scss';
@import './datatables.scss';
@import './media-query.scss';
@import './owl-carousel.scss';
@import './scroll-up.scss';
@import './sidebar.scss';
@import './timeline.scss';
