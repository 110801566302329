.slider-carousel .item {
    opacity: 0.6;
    transition: 0.4s ease all;
    transform: scale(0.8);
}

.owl-carousel .nav-btn{
    position: absolute;
    width: 70px;
    cursor: pointer;
    top: 200px;
  }
  
  .owl-carousel .prev-slide{
    content: url('/storage/images/assets/navPrev.png');
    left: 10px;
  }
  .owl-carousel .next-slide{
    content: url('/storage/images/assets/navNext.png');
    right: 10px;
  }

@include media-breakpoint-down(lg) {
    .slider-carousel .item {
        margin: 0;
        transform: scale(0.9);
    }
}
.slider-carousel .active .item {
    opacity: 1;
    transform: scale(1);
}
.slider-carousel .active {
    z-index: 99;
}
.slider-carousel .owl-item {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
    -webkit-transform: translateZ(0) scale(1, 1);
}
@include media-breakpoint-up(xl) {
    .platinum-partnerships-carousel .owl-stage {
        left: -110px;
    }
    .gold-partnerships-carousel .owl-stage {
        left: -110px;
    }
    .silver-partnerships-carousel .owl-stage {
        left: -110px;
    }
}
