// Fold, Iphone 4
@media (max-width: 359.98px) {


    // .subpage-landing {
    //     padding-top: 2rem;
    //     height: 90vh !important;
    //     min-height: 90vh !important;
    //     background-size: 100% 40%, cover !important;
    // }

    .masthead {
        background-size: auto 120% !important;
        background-position: center left !important;
    }

    .text-landing,
    .text-landing-outline {
        font-size: 28px !important;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

    // .subpage-landing {
    //     padding-top: 2rem;
    //     height: 80vh;
    //     min-height: 80vh;
    //     background-size: 100% 30%, cover;
    // }

    .masthead {
        background-size: auto 100%;
        background-position: center left;
    }

    .text-landing,
    .text-landing-outline {
        font-size: 38px;
    }

    .rounded-100-dleft {
        border-radius: 0 75px 0 75px;
        padding: 5rem 0 6rem 0;
    }

    .rounded-100-dright {
        border-radius: 75px 0 75px 0;
        padding: 5rem 0 6rem 0;
    }

    .text-sublanding {
        font-size: 40px;
    }

    .rounded-30 {
        border-radius: 20px;
    }

    .owl-carousel .nav-btn {
        width: 50px !important;
        top: 40px !important;
    }

    .owl-carousel .prev-slide {
        left: -15px !important;
    }

    .owl-carousel .next-slide {
        right: -15px !important;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

    // .subpage-landing {
    //     padding-top: 2rem;
    //     height: 120vh !important;
    //     min-height: 120vh !important;
    //     background-size: 100% 40%, cover !important;
    // }

    .masthead {
        height: 130vh;
        background-size: auto 190%;
        background-position: center left;
    }

    .text-landing,
    .text-landing-outline {
        font-size: 42px;
    }

    .rounded-30 {
        border-radius: 20px;
    }

    .owl-carousel .nav-btn {
        width: 70px !important;
        top: 80px !important;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

    // .subpage-landing {
    //     padding-top: 2rem;
    //     height: 50vh !important;
    //     min-height: 50vh !important;
    //     background-size: 100% 40%, cover !important;
    // }

    .masthead {
        min-height: 60vh;
        height: 60vh;
        background-size: auto 180%;
        background-position: center left;
    }

    .text-landing,
    .text-landing-outline {
        font-size: 56px;
    }

    .text-sublanding {
        font-size: 44px;
    }

    .owl-carousel .nav-btn {
        top: 100px !important;
    }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

    // .subpage-landing {
    //     padding-top: 2rem;
    //     height: 50vh !important;
    //     min-height: 50vh !important;
    //     background-size: 100% 50%, cover !important;
    // }

    .masthead {
        min-height: 40vh;
        height: 60vh;
        background-position: center center;
    }

    .text-landing,
    .text-landing-outline {
        font-size: 32px;
    }

    .owl-carousel .nav-btn {
        top: 150px !important;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1299.98px) {}
